import React from 'react';
import { Container, Table } from 'react-bootstrap';
import KB3Banner from './../Media/KB Summer Classic 2025.jpeg';
import NavbarStyle from '../Navbar/Navbar.module.css';
import SummerCollegeRuns from './SummerCollegeRuns.module.css';
import LeagueTable from './HSDraftLeagueTable';
import ScheduleTable from './ScheduleCollegeTable';

function Schedule() {
  const leagueDetails = {
    name: '2025 Boston Elite Summer Classic',
    event: 'Summer Classic',
    dateRange: 'June 11th - August 20th',
    days: 'Wednesday Nights',
    time: '5-10PM',
    location: 'Brandeis University',
    locationDetails: 'The Gosman Sports and Convocation Center',
    locationAddress: '415 South St, Waltham, MA 02453',
    registrationLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSfAcZ6B_2vbF58SiRc8GBXVh5tO6WqdM0pJxUCjM1s9u9FFOw/viewform',
    ages: 'College',
  };

  const schedule = {
    'July 9th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
    'July 16th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
    ],
    'July 23rd': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
    ],
    'July 30th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
      },
    ],
  };

  const pre_break_schedule = {
    'June 11th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
    ],
    'June 18th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],

        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
    ],
    'June 25th': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
        winner: 'TBD',
      },
    ],
  };

  return (
    <Container>
      <img
        src={KB3Banner}
        alt={`${leagueDetails.name} ${leagueDetails.event}`}
        width='100%'
        className='image-fluid'
      />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div>
          <LeagueDetailsTable leagueDetails={leagueDetails} />
        </div>

        <div>
          <div style={{ margin: '20px 0 40px 0' }}>
            <a
              href={leagueDetails.registrationLink}
              target='_blank'
              rel='noopener noreferrer'
              className={SummerCollegeRuns['register-button']}
            >
              Register Now
            </a>
          </div>
        </div>
        {/* <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
          <h2>Team Standings</h2>
          <LeagueTable
            scheduleResults={{ ...pre_break_schedule, ...schedule }}
          />
        </div>
        <div>
          <h2>Schedule</h2>
          <ScheduleTable schedule={pre_break_schedule} />
          <div style={{ margin: '50px 0' }}>
            <h3>July 2rd</h3>
            <h5>No games - July 4th break</h5>
          </div>
          <ScheduleTable schedule={schedule} />
        </div> */}
      </div>
    </Container>
  );
}

function LeagueDetailsTable(props) {
  const { leagueDetails } = props;
  console.log('leagueDetails:', leagueDetails);

  return (
    <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
      <h2>{leagueDetails.name}</h2>
      <Table
        striped
        bordered
        hover
        variant='dark'
        style={{ marginTop: '20px' }}
      >
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Ages</td>
            <td>{leagueDetails.ages}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Dates</td>
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.dateRange}</p>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.days}</p>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Time</td>
            <td>{leagueDetails.time}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Location</td>
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.location}</p>
              <p style={{ marginBottom: '0px' }}>
                {leagueDetails.locationDetails}
              </p>
              <p style={{ marginBottom: '0px' }}>
                {leagueDetails.locationAddress}
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Schedule;
