import React, { useState, useRef } from 'react';
// import './Accordion.css';
import AccordionStyle from './Accordion.module.css';
import NavDropdownStyle from './NavDropdown.module.css';
// import './Navbar.css';
import NavbarStyle from './Navbar.module.css';
import { Link, NavLink } from 'react-router-dom';
import Logo from './Logo 13.jpg';
import { Container } from 'react-bootstrap';

export default function Navbar(props) {
  let nav_content = useRef(null);
  let sub_content = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [isSubActive, setSubIsActive] = useState(false);
  const [height, setHeight] = useState(0);
  const [subHeight, setSubHeight] = useState(0);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownNew, setDropdownNew] = useState(false);
  // const [backgroundColorState, setBackgroundColorState] = useState('red');

  function toggleSubNavActive() {
    // console.log(isSubActive);
    setSubIsActive(!isSubActive);
    // console.log(isSubActive);
    setSubHeight(isSubActive ? 0 : sub_content.current.scrollHeight);
    // console.log(height);
    // console.log(subHeight);
    // console.log(nav_content.current.scrollHeight);
    // console.log(sub_content.current.scrollHeight);
    // console.log(typeof subHeight);
    setSubHeight(
      subHeight === sub_content.current.scrollHeight
        ? 0
        : sub_content.current.scrollHeight
    );
    console.log(subHeight);
    if (!isSubActive) {
      setHeight(height + sub_content.current.scrollHeight);
      // console.log('adding');
      // console.log(height);

      // setBackgroundColorState('red');
    } else {
      setHeight(height - sub_content.current.scrollHeight);
      // console.log('subtract');
      // setBackgroundColorState('yellow');
    }
  }

  function toggleMobileNavActive() {
    // console.log('toggle mobile nav active');
    // console.log(isActive);
    setIsActive(!isActive);

    setHeight(isActive ? 0 : nav_content.current.scrollHeight);
    if (isActive) {
      setSubHeight(0);
      setSubIsActive(false);
    }
    // console.log(height);
    // console.log(nav_content.current.scrollHeight);
  }

  const handleClick = () => setClick(!click);

  const onMouseEnter = () => {
    console.log(dropdown);
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
    console.log(dropdown);
  };

  const onMouseLeaveMenu = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const onMouseLeaveButton = () => {
    if (!click) {
      setDropdown(false);
    }
  };

  const onMouseEnterNew = () => {
    console.log(dropdown);
    if (window.innerWidth < 960) {
      setDropdownNew(false);
    } else {
      setDropdownNew(true);
    }
    console.log(dropdownNew);
  };

  const onMouseLeaveMenuNew = () => {
    if (window.innerWidth < 960) {
      setDropdownNew(false);
    } else {
      setDropdownNew(false);
    }
  };

  const onMouseLeaveButtonNew = () => {
    if (!click) {
      setDropdownNew(false);
    }
  };

  return (
    <div>
      <nav
        style={{
          backgroundColor: 'rgb(49, 90, 163)',
        }}
        onMouseLeave={onMouseLeaveMenu}
      >
        <Container>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '80px',
              justifyContent: 'space-between',
              padding: '0px 1% ',
            }}
          >
            <div>
              <Link to='/'>
                <img src={Logo} height='60px' alt='' />
              </Link>
            </div>

            <div style={{ height: '100%' }}>
              <ul className={NavbarStyle['custom-nav-menu-desktop']}>
                {NavbarItems.map((item, index) => {
                  return (
                    <li key={index} className={NavbarStyle['custom-nav-item']}>
                      {item.path.startsWith('http') ||
                      item.path.startsWith('www') ? (
                        // For external links, use <a> tag
                        <a
                          href={item.path}
                          className={`${NavbarStyle['custom-nav-links']}`} // Adjust the className for external links if needed
                          target='_blank' // Opens in a new tab
                          rel='noopener noreferrer' // For security reasons
                        >
                          {item.title}
                        </a>
                      ) : (
                        // For internal links, use NavLink
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${NavbarStyle['custom-nav-links']} ${NavbarStyle['nav-active']}`
                              : NavbarStyle['custom-nav-links']
                          }
                          to={item.path}
                        >
                          {item.title}
                        </NavLink>
                      )}
                    </li>
                  );
                })}

                {/* <span
                  onMouseLeave={onMouseLeaveButton}
                  onMouseEnter={onMouseEnter}
                  style={{ marginTop: '10px', padding: '0px 0 10px 0px' }}
                  className={NavbarItems['custom-nav-item']}
                >
                  <li className={NavbarItems['custom-nav-item']}>
                    <span
                      className={
                        dropdown
                          ? NavbarStyle['custom-nav-dropdown-item'] +
                            ' ' +
                            NavbarStyle['dropdown-hover']
                          : NavbarStyle['custom-nav-dropdown-item']
                      }
                      // // className={NavbarStyle['custom-nav-dropdown-item']}
                      onClick={handleClick}
                    >
                      Services <i className='fas fa-caret-down' />
                    </span>
                    {dropdown && <NavDropdown />}
                  </li>
                </span> */}
              </ul>
            </div>
            <div className={NavbarStyle['nav-desktop-menu']}>
              <ul style={{ listStyle: 'none', margin: '0px' }}>
                <li className={NavbarStyle['custom-nav-item']}>
                  <div
                    className={NavbarStyle['menu-icon']}
                    onClick={toggleMobileNavActive}
                  >
                    <i
                      className={
                        isActive
                          ? 'fas fa-times ' + +NavbarStyle['fa-times']
                          : 'fas fa-bars ' + NavbarStyle['fa-bars']
                      }
                    />
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className='nav-desktop-menu'>
            <ul style={{ listStyle: 'none', margin: '0px' }}>
              <li className='custom-nav-item'>
                <div className='menu-icon' onClick={toggleMobileNavActive}>
                  <i className={isActive ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
              </li>
            </ul>
          </div> */}
          </div>
          <div className={NavbarStyle['accordion-section']}>
            <div
              ref={nav_content}
              style={{ maxHeight: `${height}px` }}
              className={NavbarStyle['accordion-content']}
            >
              <div className={AccordionStyle['accordion-content-text']}>
                <ul
                  style={{
                    listStyle: 'none',
                    padding: '0px',
                    margin: '15px 0px',
                  }}
                >
                  <div className={AccordionStyle['accordion-content-text']}>
                    <ul
                      style={{
                        listStyle: 'none',
                        padding: '0px',
                        margin: '15px 0px',
                      }}
                    >
                      {NavbarItems.map((item, index) => {
                        // Check if the item path is for external link
                        const isExternalLink =
                          item.path.startsWith('http') ||
                          item.path.startsWith('www');
                        return (
                          <li
                            key={index}
                            className={NavbarStyle['custom-nav-item']}
                          >
                            {isExternalLink ? (
                              // For external links, use <a> tag
                              <a
                                href={item.path}
                                className={NavbarStyle['nav-links-mobile']} // Adjust the className for external links if needed
                                target='_blank' // Opens in a new tab
                                rel='noopener noreferrer' // For security reasons
                              >
                                {item.title}
                              </a>
                            ) : (
                              // For internal links, use NavLink
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? `${NavbarStyle['nav-links-mobile']} ${NavbarStyle['nav-active']}`
                                    : NavbarStyle['nav-links-mobile']
                                }
                                to={item.path}
                              >
                                {item.title}
                              </NavLink>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* <button
                    className={
                      NavbarStyle['accordion-nav-button'] +
                      ' ' +
                      NavbarStyle['custom-accordion-button']
                      // 'accordion-nav-button custom-accordion-button'
                      // 'accordion-nav-button'
                      // 'custom-accordion-button'
                    }
                    onClick={toggleSubNavActive}
                  >
                    <span className={AccordionStyle['accordion-title']}>
                      Test
                    </span>
                  </button>
                  <div className={NavbarStyle['accordion-section']}>
                    <div
                      ref={sub_content}
                      style={{
                        maxHeight: `${subHeight}px`,
                      }}
                      className={NavbarStyle['accordion-content']}
                    >
                      <div>
                        <div
                          style={{
                            display: 'hidden',
                            backgroundColor: 'pink',
                            borderRadius: '30px',
                            padding: '20px',
                            margin: '10px 30px',
                          }}
                        >
                          {DropdownItems.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className={NavbarStyle['custom-nav-item']}
                              >
                                <NavLink
                                  activeClassName={NavbarStyle['nav-active']}
                                  className={NavbarStyle['nav-links-mobile']}
                                  to={item.path}
                                  // onClick={() => setClick(false)}
                                >
                                  {item.title}
                                </NavLink>
                              </li>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {NavbarItems.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={NavbarStyle['custom-nav-item']}
                      >
                        <NavLink
                          activeClassName={NavbarStyle['nav-active']}
                          className={NavbarStyle['nav-links-mobile']}
                          to={item.path}
                          // onClick={() => setClick(false)}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    );
                  })} */}
                </ul>
              </div>
            </div>
          </div>
          <hr style={{ margin: '0px' }} />
        </Container>
      </nav>
    </div>
  );
}

export const NavbarItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About',
    path: '/about',
  },
  // {
  //   title: 'KB3 Winter Runs',
  //   path: '/winter-runs',
  // },
  {
    title: 'The Draft League',
    // path: 'draft-league',
    path: 'https://www.goldstandardhoops.com/summer-draft-league',
  },
  {
    title: 'Summer Classic',
    path: '/summer-classic',
  },
  {
    title: 'Training',
    path: '/training',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];

export const DropdownItems = [
  {
    title: 'A Very Long Dropdown Item That Will Not Fit on One Line',
    path: '/marketing',
    cName: NavDropdownStyle['dropdown-link'],
  },
  {
    title: 'Consulting',
    path: '/consulting',
    cName: NavDropdownStyle['dropdown-link'],
  },
  {
    title: 'Design',
    path: '/design',
    cName: NavDropdownStyle['dropdown-link'],
  },
  {
    title: 'Development',
    path: '/development',
    cName: NavDropdownStyle['dropdown-link'],
  },
];
